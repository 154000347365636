export const navbarInfo = [
    {
        title: 'Hem',
        url: '/home',
        cName: 'nav-links'
    },
    {
        title: 'Erfarenheter',
        url: '/experiences',
        cName: 'nav-links'
    },
    {
        title: 'Projekt',
        url: '/projects',
        cName: 'nav-links'
    },
    {
        title: 'Kontakt',
        url: '/contact',
        cName: 'nav-links'
    }
]