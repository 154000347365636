import * as creperiet from './Jag-creperiet.jpg'
import * as happyBeach from './Jag-glad-strand.jpg'
import * as meRauk from './Jag-rauk.jpg'

const imageUrl = {
    creperiet,
    happyBeach,
    meRauk
}

export default imageUrl;